import { Box, Text, Image, Link as ChakraLink, Button, Center, useToast, Flex } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import CopyToClipboard from "react-copy-to-clipboard";
import AddThis from "../misc/AddThis";
import Fancybox from "../misc/Fancybox";

interface JournalEntryProps extends JournalEntry {
  photoUrl: string;
  groupname?: string;
}

const JournalEntry = (props: JournalEntryProps): JSX.Element => {
  const toast = useToast()

  const showToast = () => {
    toast({
      position: 'bottom',
      render: () => (
        <Flex
          color='brand.green.600'
          p={3}
          bg='whiteAlpha.900'
          borderRadius={'lg'}
          borderColor='brand.green.600'
          borderWidth={'1px'}
          alignItems='center'
          justifyContent={'center'}
        >
          <FontAwesomeIcon
            icon={['fas', 'check']}
            color={`brand.green.600`}
            style={{ marginRight: '10px' }}
          />
          <Text color="brand.darkgray.800">Link copied to clipboard!</Text>
        </Flex>
      ),
    })
  }

  return (
    <Box width={{ base: "95%", lg: "100%" }} >

      <Flex gap={3}>
        <Text as="h3" color="brand.green.600" fontSize={{ base: "xl", lg: "3xl" }} id={`entry-${props.journalID}`}>{props.entrytitle}</Text>
        <CopyToClipboard
          text={`https://www.ppm.org/mission-trip-journals/${props.tripID}#entry-${props.journalID}`}
          onCopy={showToast}
        >
          <Button
            leftIcon={<FontAwesomeIcon icon={['fas', 'share-nodes']} />}
            variant="ghost"
            colorScheme="brand.green"
          ></Button>
        </CopyToClipboard>
      </Flex>

      { props.groupname &&
      <Text> Group Name: <span>{props.groupname}</span></Text>
      }

      <Text>by {props.author} | {props.journalDate}</Text>

      <div dangerouslySetInnerHTML={{ __html: props.entry }} style={{ marginBottom: '15px' }}></div>

      {
        props.photoUrl !== '' || props.photo === 1 ? (
          <Fancybox options={{ infinite: false }}>
            <Image
              cursor="pointer"
              data-fancybox="gallery"
              data-src={
                props.photoUrl !== ''
                  ? props.photoUrl
                  : `https://trip-journals.storage.googleapis.com/${props.tripID}/${props.journalID}.jpg`
              }
              data-caption={`
          <b style="margin-bottom: 0px;">${props.entrytitle}</b><br />
          <em style="font-size: 12px;">by ${props.author}, ${props.journalDate}</em><br />
          ${props.entry.replaceAll('<p', '<p style="color:white;"')}`}
              src={
                props.photoUrl !== ''
                  ? props.photoUrl
                  : `https://trip-journals.storage.googleapis.com/${props.tripID}/${props.journalID}.jpg`
              }
              alt={props.entrytitle}
              w="full"
              h="auto"
              borderRadius={'xl'}
            />
          </Fancybox>
        ) : null
      }


    </Box>
  );
}

export default JournalEntry;