import { Box, Button, Container, Divider, Flex, Grid, GridItem, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { storyblokEditable } from "@storyblok/react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { getRecentDisasterJournals } from "../../utils/ppmApi";
import JournalEntry from "../journals/JournalEntry";
import ContactForm from "../misc/contact-form/ContactForm";
import DisasterResponseSubscribe from "./DisasterResponseSubscribe";


interface Props {
  blok: any;
  globalState: GlobalState;
  componentProps: ComponentSpecificProps,
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}


const DisasterResponseUpdates = ({ blok, globalState, setGlobalState }: Props): JSX.Element => {
  const [journals, setJournals] = useState<JournalEntry[]>([]); 
  const [error, setError] = useState<string | null>(null);
  const [visibleCount, setVisibleCount] = useState<number>(10);
  const disasterID = globalState.thisDisaster?.disasterInfo?.disasterID;

  useEffect(() => {
    const fetchJournals = async () => {
      try {
        const recentJournals = await getRecentDisasterJournals(disasterID);
        setJournals(recentJournals);
      } catch (error) {
        setError("Failed to load disaster journals. Please try again later.");
      }
    };

    if (disasterID) {
      fetchJournals();
    }
  }, [disasterID]);

  if (error) {
    return <Text textAlign="center" color="red.500">{error}</Text>;
  }

  const entryList: JournalEntry[] = globalState.thisDisaster
  ? globalState.thisDisaster.entryList
  : globalState.componentSpecificData?.recentDisasterUpdates || [];

  const filteredJournals = journals.filter(
    (entry) => entry.author !== "Praying Pelican Missions"
  );

  const combinedJournals = [...entryList, ...filteredJournals];

  const visibleJournals = combinedJournals.slice(0, visibleCount);

  return (
    <Container maxW="container.xl">
      <Grid templateColumns={{ base: '1fr', lg: '2fr 1fr' }} gap={10} mt="10">

        <GridItem>
          {visibleJournals.length > 0 && (
            <Box mb="10" id="updates" {...storyblokEditable(blok)}>
              <Text
                as="h2"
                my="5"
                textTransform="uppercase"
                color="brand.green.600"
              >
                Updates From The Field
              </Text>
              <VStack
                gap="6"
                divider={
                  <Divider
                    borderColor="brand.darkgray.200"
                    sx={{ marginBottom: "-10px!important" }}
                  />
                }
              >
                {visibleJournals.map((entry, index) => (
                  <JournalEntry
                    {...entry}
                    key={index}
                    photoUrl={
                      entry.photo === 0
                      ? '' 
                      : entry.journalID && entry.tripID
                      ? `https://trip-journals.storage.googleapis.com/${entry.tripID}/${entry.journalID}.jpg` 
                      : `https://images.ppm.org/upload/disasterjournalpics/${entry.journalID}.jpg` 
                  }
                  />
                ))}
              </VStack>
              {visibleCount < filteredJournals.length && (
                <Flex justifyContent="center" mt="5">
                  <Button
                    onClick={() => setVisibleCount((prev) => prev + 10)} 
                    colorScheme="brand.green"
                  >
                    Load More
                  </Button>
                </Flex>
              )}
            </Box>
          )}
        </GridItem>

        <GridItem>
          <Flex direction="column" gap="5" align="center">
            <Box w="100%">
              <DisasterResponseSubscribe
                blok={null}
                globalState={globalState}
              />
            </Box>
            <Box
              borderColor="brand.orange.600"
              borderWidth="1px"
              borderRadius="lg"
              p="3"
              w="100%"
            >
              <Text
                color="brand.darkgray.600"
                fontWeight="bold"
                textAlign="center"
                fontSize="lg"
                mb="4"
              >
                <FontAwesomeIcon icon="circle-info" style={{ marginRight: '5px' }} />
                REQUEST INFO
              </Text>
              <ContactForm
                blok={{
                  colorScheme: 'light',
                  fieldSize: 'sm',
                  showOptionalToggle: false,
                  showLabels: false,
                  submitText: 'REQUEST RELIEF INFO',
                  contactFormType: 'disaster-contact',
                }}
                globalState={globalState}
                setGlobalState={setGlobalState}
              />
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default DisasterResponseUpdates;